
@font-face {
    font-family: "BeamNewHMK-VF";
    src: url(./Beam-New-VF-HMK.9730d1ca.ttf) format("truetype")
}

@font-face {
    font-family: "BeamNewHMK-170";
    src: url(./BeamNewHMK-Bold.25324206.ttf) format("truetype");
    src: url(./BeamNewHMK-Bold.a1e09de2.woff) format("woff");
    src: url(./BeamNewHMK-Bold.eca1355d.woff2) format("woff2")
}

@font-face {
    font-family: "BeamNewHMK-114";
    src: url(./BeamNewHMK-ExtraMedium.11b00429.ttf) format("truetype");
    src: url(./BeamNewHMK-ExtraMedium.87ab4ed6.woff) format("woff");
    src: url(./BeamNewHMK-ExtraMedium.ff36b6a7.woff2) format("woff2")
}

@font-face {
    font-family: "BeamNewHMK-84";
    src: url(./BeamNewHMK-SemiMedium.5e166a2e.ttf) format("truetype");
    src: url(./BeamNewHMK-SemiMedium.f7bd89b6.woff) format("woff");
    src: url(./BeamNewHMK-SemiMedium.2745244f.woff2) format("woff2")
}
