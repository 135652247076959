/* General Styles */
/* 
* {
  box-sizing: border-box;
} */

:root {
  --color-white: #fff;
  --color-black: #000;
  --color-lightGray: #f9f9f9;
  --color-dark: #22322f;
  --color-darkGray: #333333;
  --color-primary: #71a89e;
  --header-height: 7.5rem; /* 120px */
}

/* body {
  margin: 0;
  padding: 0;
  background-color: var(--color-lightGray);
  font-size: 1.125rem;
}

.body--noscroll {
  overflow: hidden;
} */

.landing-page a {
  display: inline-block;
  text-decoration: none;
  /*color: var(--color-dark);*/
}

.landing-page .container {
  max-width: 1200px;
  /*margin: auto;  DONT DO THIS. IT ALTERS ALL THE PAGES AND PUSHES STUFF DOWN*/
}

/* Spacing Rules */
.container-pall {
  /* all */
  padding: 1.875rem 1.5625rem;
  display: flex;
}

.container-py {
  /* y-axis */
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}
.container-pt {
  /* top */
  padding-top: 1.875rem;
}
.container-pb {
  /* bottom */
  padding-top: 1.875rem;
}

.container-px {
  /* x-axis */
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
}
.container-pl {
  /* left */
  padding-left: 1.5625rem;
}
.container-pr {
  /* right */
  padding-right: 1.5625rem;
}

.flex {
  display: flex;
}

.flex-jc-sp {
  justify-content: space-between;
}

.flex-jc-c {
  justify-content: space-between;
}

.flex-ai-c {
  align-items: center;
}

/* Visibility */
@media (min-width: 48rem) {
  /* 768px */
  .hide-for-desktop {
    display: none;
  }
}

@media (max-width: 47.9375rem) {
  /* 767px */
  .hide-for-mobile {
    display: none;
  }
}

/* End General Styles */

.header {
  height: var(--header-height);
}

.nav {
  height: 100%;
  flex-wrap: wrap;
}

@media (max-width: 18.75rem) {
  /* 300px */
  .nav {
    justify-content: center;
  }
}

.header__logo {
  font-size: 1.875rem; /* 30px */
  font-weight: 700;
}

.toggle-menu {
  position: relative;
  z-index: 1000;
  min-width: 8.125rem; /* 130px */
  min-height: 3.125rem; /* 50px */
  justify-content: space-between;
  align-items: center;
  border: 2px solid var(--color-dark);
  border-radius: 10px;
  box-shadow: 2px 2px 0 var(--color-dark);
  padding: 0 1.5625rem; /* 0 25px */
  cursor: pointer;
  transition: all 300ms ease-in-out;
}

.header--active .toggle-menu {
  background-color: var(--color-white);
  box-shadow: none;
}

.toggle-menu:hover {
  opacity: 0.7;
}

.toggle-menu__dots {
  width: 1.375rem; /* 22px */
  height: 0.9375rem; /* 15px */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 5px 0px;
  transition: all 300ms ease-in-out;
}

.header--active .toggle-menu__dots {
  transform: scale(0.9);
}

.toggle-menu__dot {
  width: 5px;
  height: 5px;
  display: inline-block;
  background-color: var(--color-dark);
  border-radius: 50%;
}

.toggle-menu__text {
  font-size: 1.1rem; /* 17.6px */
  font-weight: 700;
}

.menu {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  transform: translateX(-50%);
  width: 100vw;
  height: 100vh;
  background-color: var(--color-white);
  background-image: radial-gradient(
    circle,
    rgb(255 255 255) 40%,
    rgb(208 208 208) 100%
  );
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 500ms ease-in-out;
}

@media (min-width: 48rem) {
  /* 768px */
  .menu {
    position: static;
    transform: translateX(0);
    visibility: visible;
    opacity: 1;
    width: auto;
    height: auto;
    background-color: transparent;
    background-image: none;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
  }
}

/* Open Menu */
.header--active .menu {
  transform: translateX(0);
  visibility: visible;
  opacity: 1;
}

.menu__item {
  list-style-type: none;
}

.menu__item:not(:last-child) {
  margin-bottom: 1.875rem; /* 30px */
}

@media (min-width: 48rem) {
  /* 768px */
  .menu__item:not(:last-child) {
    margin-bottom: 0px;
    margin-right: 2.5rem; /* 40px */
  }
}

.menu__link {
  font-size: 1.25rem; /* 20px */
  font-weight: 400;
  transition: all 200ms ease-in-out;
}

.menu__link:hover {
  color: var(--color-primary);
}

.menu__link--primary {
  font-weight: bold;
  color: var(--color-primary);
}

.menu__link--primary:hover {
  color: var(--color-dark);
}

.hero {
  /*min-height: calc(100vh - var(--header-height)); no min height we want to see stuff under*/
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ffa976;
  /* background-color: #ff9d62; */
  position: relative;
}

@media (max-width: 18.75rem) {
  /* 300px */
  .hero {
    text-align: center;
  }
}

@media (min-width: 48rem) {
  /* 768px */
  .hero {
    justify-content: space-between;
  }
}

video.hero-bg-video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.hero__image {
  line-height: 0;
  align-self: flex-end;
}

.hero__illustration-image {
  width: min(19.25rem, 100%); /* 308px - 100% */
  height: min(18.8125rem, 100%); /* 301px - 100% */
}

@media (min-width: 48rem) {
  /* 768px */
  .hero__illustration-image {
    width: 23.125rem; /* 370px */
    height: auto;
    margin-left: 3.125rem; /* 50px */
  }
}

@media (min-width: 70rem) {
  /* 1024px */
  .hero__illustration-image {
    width: 40rem; /* 500px */
  }
}




.hero__text {
  text-align: center;
  padding: 24px; /* mobile */
}
@media (max-width: 767px) {
  /* xs sm */
  .hero__text__qr {
    padding-bottom: 0;
  }
  .hero__text__digital {
    position: relative;
    padding-bottom: 0;
    margin-bottom: -15vw;
    z-index: 200;
  }
}



@media (min-width: 64rem) {
  /* 1024px */
  .hero__text {
    text-align: center;
    padding: 48px; /* mobile */
    width: 100%;
    margin: 0px auto;
  }
}

.hero__heading {
  font-size: 1.875rem; /* 30px */
  font-weight: 400;
  line-height: 1.6;
}

@media (min-width: 64rem) {
  /* 1024px */
  .hero__heading {
    font-size: 1.2rem;
    font-weight: 700;
  }
}

.hero__description {
  font-size: 1rem; /* 19px */
  line-height: 1.7;
  font-weight: 300;
}

.hero__cta {
  color: var(--color-dark);
  border: 2px solid var(--color-dark);
  border-radius: 10px;
  padding: 0.9375rem 1.875rem; /* 15px - 30px */
  font-size: 1.125rem;
  font-weight: bold;
  text-align: center;
  transition: all 300ms ease-in-out;
}

.hero__cta--primary {
  background-color: var(--color-primary);
  color: var(--color-white);
  box-shadow: 2px 2px 0 var(--color-dark);
}

.hero__cta--primary:hover,
.hero__cta--primary:focus {
  background-color: var(--color-white);
  color: var(--color-dark);
  box-shadow: none;
}



/* @import url(https://fonts.googleapis.com/css?family=Lato:400,600,700); */
.step_container {
  display: grid;
  margin: 0px auto;
  justify-items: center;
}
/* DONT CHANGE ANY OF THESE MARGINS WOUTHT TALKING TO ERIC */
/* THIS IS NOT SIMPLE OR WHAT YOU WOULD EXPECT */
.react-multi-carousel-item:nth-child(1) .step_container  {
  padding-left: 30px;
  padding-right: 10px;
}
.react-multi-carousel-item:nth-child(2) .step_container  {
  padding-left: 20px;
  padding-right: 20px;
}
.react-multi-carousel-item:nth-child(3) .step_container  {
  padding-left: 10px;
  padding-right: 30px;
}

.step_snap {
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.15); */
  color: #ffffff;
  float: left;
  /* font-family: "Lato", Arial, sans-serif; */
  font-size: 16px;
  overflow: hidden;
  position: relative;
  text-align: left;
  width: 100%;
}

.step_snap * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.step_snap img {
  max-width: 100%;
  vertical-align: top;
  position: relative;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.step_snap figcaption {
  padding: 8px 20px;
  position: absolute;
  bottom: 0;
  z-index: 1;
  width: 100%;
  text-align: center;
}

.step_snap figcaption:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #7c6da5;
  content: "";
  background: -moz-linear-gradient(
    90deg,
    #7c6da5 0%,
    #7c6da5 100%,
    #7c6da5 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    #7c6da5 0%,
    #7c6da5 100%,
    #7c6da5 100%
  );
  background: linear-gradient(90deg, #7c6da5 0%, #7c6da5 100%, #7c6da5 100%);
  opacity: 0.8;
  z-index: -1;
}

.step_snap .steps {
  background-color: #dda882;
  border-radius: 50%;
  color: white;
  border: 1px solid #7c6da5;
  font-size: 18px;
  font-weight: 700;
  min-height: 48px;
  min-width: 48px;
  padding: 15px 0;
  position: absolute;
  left: 15px;
  text-align: center;
  text-transform: uppercase;
  top: -25px;
}

.step_snap .steps span {
  display: block;
  line-height: 14px;
}

.step_snap .steps .month {
  font-size: 11px;
}

.step_snap h6,
.step_snap p {
  margin: 0;
  padding: 0;
}

.step_snap h6 {
  display: inline-block;
  font-weight: 500;
  letter-spacing: -0.4px;
  margin-bottom: 5px;
}

.step_snap p {
  font-size: 0.8em;
  line-height: 1.6em;
  margin-bottom: 0px;
}

.step_snap a {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  z-index: 1;
}


.figure_desp p {
  /* padding: 10px;
  text-align: center; */
  /* margin-bottom: 0px; */
  /* padding: 10px; */
}

/* .snip1527:hover img,
.snip1527.hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
} */

/* Demo purposes only */
/* 
body {
  background-color: #212121;
} */

/* .slider {
  margin: 0 20px;
  overflow: "hidden";
  padding: 2rem 0;
}

.slider img {
  width: 100%;
  border-radius: 10px;
}

.react-multi-carousel-list {
  padding: 0rem 0 2rem 0;
}

.custom-dot-list-style button {
  border: none;
  background: rgb(255, 68, 68);
}
.react-multi-carousel-dot.react-multi-carousel-dot--active button {
  background: rgb(255, 68, 68) !important;
} */

.custom-dot-list-style button {
  border: none;
  background: rgb(97 64 149);
}
.react-multi-carousel-dot.react-multi-carousel-dot--active button {
  background: #614095 !important;
}
.react-multi-carousel-dot button {
  background:#d6d6de;
  border:none !important;
  width: 8px !important;
  height: 8px !important;
  margin-right: 12px !important;
}

.video-segment {
  background: #faf7f5;
}
.video-segment img {
  width: 100%;
}
.video-segment-pad {
  padding: 50px 10px;
}
.video-thumb img {
  width: 100%;
}
.theme_heading {
  display: flex;
  justify-content: space-between;
}
.View p {
  color: #614095;
  text-decoration: 2px solid underline;
  margin: 5px;
}

.landing_title{
  font-family: "IBM Plex Serif", sans-serif;
  font-size: 22px; 
  font-weight: 300;
  color: #48464f;
}

h4.landing_title {
  font-size: 30px;
}

ul.react-multi-carousel-track {
    padding-bottom: 25px;
}

.video-count-2, .video-count-3 {
  display: none;
}
@media (min-width: 768px)   {
  /* MD and larger */
  .video-count-2, .video-count-3 {
    display: block;
  }

  h4.landing_title {
    font-size: 36px;
  }
}

.slide {
  background-color: purple;
  width: 200px;
  height: 100px;
}

.carousel-wrapper {
  margin-top: 100px;
}





@media (min-width: 768px)   {
  /* MD and larger */
  .react-multi-carousel-dot.react-multi-carousel-dot--active button {
    visibility: hidden !important;
  }
  .react-multi-carousel-dot {
    visibility: hidden !important;
  }
}

.ta-banner-btn {
  width: 100%;
}

.shop-cards-title {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #48464E;
}

.shop-cards-btn {
  width: 70%;
}

