/* @import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap"); */
/* <link href="https://fonts.googleapis.com/css?family=Montserrat:400,500,700,900|Ubuntu:400,500,700" rel="stylesheet"> */

/*@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,700,900|Ubuntu:400,500,700");*/
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@400;500;600&display=swap");

body {
  font-family: "BeamNewHMK-114", sans-serif;
  background-color: white;
  font-weight: 500;
  color: #48464f;
}
/* link start */

a:hover {
  text-decoration: none;
}
.back_btn a:hover, a.mob_buttonIcon:hover{
  color:#3e88c2 !important;
  text-decoration: underline !important;
}
/* link end */



.blue-link {
  color: #095c9c;
  text-decoration: underline;
  font-size: 15px;
  font-weight:500;
  padding: 0;
  vertical-align: baseline;
}
.blue-link:hover {
  color: #2679ba;
  text-decoration: underline;
}
.blue-link.focus,
.blue-link:focus {
  text-decoration: underline;
}
.blue-link.btn-padded {
  padding: 10px 0;
}
small {
  font-size: 12px;
}
small .blue-link {
  font-size: 12px;
}
.subheadline .blue-link {
  font-family: "BeamNewHMK-114";
  font-size: 15px;
}
.subheadline strong {
  font-family: "BeamNewHMK-114";
  font-weight: normal;
}

.btn.btn-large {
  font-size: 15px;
  padding: 14px 27px;
  font-family: "BeamNewHMK-170";
  border-radius: 8px;
}
.btn.btn-danger {
  border: none;
  padding: 10px 20px;
}

.label-corner {
  font-family: "BeamNewHMK-114", sans-serif;
  letter-spacing: 1px;
  position: absolute;
  bottom: 3.3%;
  left: 0;
  color: white;
  background-color: #604099;
  padding: 2% 2% 1.7% 2%;
  text-transform: uppercase;
}



@media (min-width: 992px) {
  /* lg ~ Desktop */
  .w-lg-33 {
    width: 33% !important;
  }
}

/*
 *  Headlines used at the top of most pages, but headline-text could be used in other areas with the size changed
 */
.headline-text, .headline-22, .headline-offer, .headline-text-small-mobile {
  font-family: "IBM Plex Serif", sans-serif;
  font-size: 30px;
  font-weight: 300;
  color: #48464f;
  margin: 14px 0 0 0;
}
h2.headline-text {
  font-size: 20px;
}
h3.headline-text {
  font-size: 31px;
}
h5.headline-text, .headline-22 {
  font-size: 22px;
}
.headline-gap {
  margin: 20px;
}
@media (min-width: 992px) {
  .headline-text {
    font-size: 36px;
  }
  .headline-gap {
    margin: 60px 0 0 0;
  }
}

@media (max-width: 482px) {
  .headline-text-small-mobile {
    font-size: 6vw;
  }
}

h2.headline-offer {
  font-size: 18px;
}
h2.headline-offer strong {
  display: block;
  text-align: center;
  font-size: 28px;
}
h2.headline-text b, h2.headline-offer b {
  font-weight: 600;
}

ul {

}

.subheadline,
.link-line-text {
  font-family: "BeamNewHMK-84";
  font-size: 15px;
  color: #48464f;
  margin-top: 20px;
  line-height: 25px;
}

.theme-animation-overlay {
  z-index: 100;
  display: inline-block;
  left: 0;
  position: absolute;
}

.strikethrough {
  text-decoration: line-through;
}

.theme-list-item {
  margin-bottom: 2px;
}
.theme-list-image {
  display: inline-block;
  margin-left: 20px;
  min-width: 80px;
  min-height: 100px;
}
.theme-list-name {
  font-size: 24px;
  max-width: 70%;
  display: inline-block;
}
.theme-list-arrow {
  line-height: 100px;
  margin-right: 20px;
}
@media (min-width: 992px) {
  /* lg ~ Desktop */
  .theme-list-item {
    background-color: white !important;
    padding: 20px;
  }
  .theme-list-item a {
    width: 100%;
    height: 100%;
    display: inline-block;
    /*margin: 20px;*/
    padding: 25px 10px 10px 10px;
    height: 240px;
  }
  .theme-list-image {
    margin-left: 0;
  }
  .theme-list-name {
    max-width: none;
  }
  .theme-list-name,
  .theme-list-arrow {
    display: block;
  }
}

@media (max-width: 400px) {
  .theme-list-name.headline-text {
    max-width: 50%;
    height: 100%;
    position: absolute;
    margin-top: 0;
    line-height: 100px;
  }
  .theme-list-name span {
    vertical-align: middle;
    display: inline-block;
    line-height: normal;
  }

  span.theme-list-image {
      min-width: 71px;
  }

  .theme-list-item .text-lg-center {
      vertical-align: middle;
  }
}



p {
  font-weight: 500;
}
.subheader {
  text-align: center;
}

.back_btn {
  margin-top: 12px;
  margin-bottom: 0px !important;
  color: black;
  font-weight: 300;
  font-size: 15px;
  color: #095c9c;
}
.back_btn a,
.back_btn .link {
  color: #095c9c;
  text-decoration: underline;
}

.back_icon {
  margin-right: 10px;
}

.headline {
  font-family: "IBM Plex Serif", sans-serif;
  font-size: 36px;
  font-weight: normal;
  margin-top: 10px;
  color: #48464f;
}

@media (max-width: 575px ) {
  .modal-header .headline {
    font-size: 28px;
  }
}

.img_icon {
  width: 18px;
  font-size: 18px;
  margin-right: 5px;
}
.icon-down{
  width: 8px;
  margin-top: 7px;
  margin-right: 5px;
}

.icon-checkmark:after {
  content:"";
  display:block;
  left: 7px;
  top: 3px;
  width: 8px;
  height: 13px;
  border: solid black;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.preview_img {
  position: relative;
}
#preview_imgid {
  display: block;
  position: relative;
  width: 100%;
}
.preview_btn {
  position: absolute;
  right: 0px;
  bottom: 0px;
}
.invite_btn {
  margin: 90px 0px 10px;
  position: relative;
}

/* .back_icon .fa-chevron-left {
  font-weight: 400;
} */

.invite-info {
  background-color: #f3f3f3;
  margin: 0px auto;
  padding: 20px;
  font-size: 12px;
}

.nano-text {
  font-size: 8px;
  top: -10px;
  position: relative;
  text-align: center;
}
.question-info {
  font-size: 9px;
}

.card {
  border: none;
}

.select_card {
  border-radius: 0px !important;
  background-color: #c15588;
  color: white;
  margin-bottom: 10px;
}

.card_icon {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.card_icon img {
  background-color: #773b7b;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  padding: 10px;
  margin: 10px;
}
.card_icon .text {
  padding: 20px;
}
.card_icon .text h4 {
  margin-bottom: 0px;
}
.card_icon .text p {
  padding-top: 2px;
  font-size: 20px;
}

.user-icon {
  margin: 0px 5px;
}


.bg {
  min-height: 65vh;
  min-width: 100vw;
  background-color: white;
  /* clip-path: polygon(300% 0, 100% 0, 100% 100%, 0% 100%); */
  -webkit-clip-path: polygon(80% 0, 100% 0, 100% 100%, 50% 100%);
  clip-path: polygon(250% 0, 100% 0%, 100% 100%, 0% 100%);
  position: absolute;
  bottom: -1px;
  /* margin-top: 8px; */
}

.intro_txt {
  color: white;
  font-weight: 600;
  padding: 10px 50px;
  max-width: 600px;
}

#white_text {
  color: white;
}

.white_btn {
  background: white;
  border-radius: 50px;
  color: #603695;
}
.plus_btn {
  font-size: 25px;
}
.videofizz_logo {
  padding: 0px 10px;
  width: 150px;
}

.card-img-top {
  margin: 5px 0px;
}

/* login page start */

.login-form {
  /* width: 310px; */
  /* padding: 8% 0 0; */
  margin: auto;
}
.logform {
  position: relative;
  z-index: 1;
  background: #ededed;
  border-radius: 32px;
  /* max-width: 360px; */
  /* margin: 20px 10px; */
  margin: 20px 10px 0px 10px;
  padding: 35px;
  text-align: start;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}
.logform input {
  outline: 0;
  /* background: #f2f2f2; */
  width: 100%;
  border: 1px solid #c4c4c4;
  margin: 0 0 15px;
  padding: 7px;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 5px;
}
.logform button {
  outline: 0;
  background: #603695;
  width: 100%;
  border: 0;
  padding: 7px;
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 600;
}
.logform label,
p {
  font-size: 14px;
}
label {
  font-family: "BeamNewHMK-84";
}

.logform button:hover,
.logform button:active,
.logform button:focus {
  background: #603695;
}
.logform .message {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px;
}
.logform .message a {
  color: #603695;
  text-decoration: none;
}
.logform .register-form {
  display: none;
}
.forgot_txt {
  text-decoration: 1px solid underline;
  font-weight: 400;
}
.form-align {
  display: flex;
  justify-content: flex-end;
}

/* invite others textarea design start */

.highlights,
textarea {
  letter-spacing: 1px;
}

.backdrop {
  position: absolute;
  z-index: 1;
  border: none;
  background-color: #fff;
  overflow: auto;
  pointer-events: none;
  transition: transform 1s;
}

.highlights {
  white-space: pre-wrap;
  word-wrap: break-word;
  color: transparent;
}

textarea.invite-info {
  display: block;
  font-family: "BeamNewHMK-84";
  font-size: 16px;
  line-height: 25px;
  z-index: 2;
  margin: 0;
  border: none;
  border-radius: 0;
  color: #48464f;
  background-color: #f3f3f3;
  min-height: 200px;
  width: 100%;
  padding: 20px;
}

mark {
  border-radius: 3px;
  color: transparent;
  background-color: #b1d5e5;
}

.perspective .backdrop {
  transform: perspective(1500px) translateX(-125px) rotateY(45deg) scale(0.9);
}

.perspective textarea {
  transform: perspective(1500px) translateX(155px) rotateY(45deg) scale(1.1);
}

button:focus {
  outline: none;
  box-shadow: 0 0 0 2px #f3f3f3 !important;
}

.Link .link_text {
  color: #007bff !important;
  font-size: 16px !important;
  text-decoration: underline !important;
}

/* previewfizz modal start */

.icon-box {
  margin: 0 auto;
  padding-bottom: 20px;
}
.modal-content > div {
  border: none;
}

.modal-title {
  margin: 0 auto;
}
.modal-body {
  font-family: "BeamNewHMK-84";
  font-size: 15px;
  color: #48464f;
  padding-bottom: 0;
}
.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
  padding: 10px;
  top: 5%;
}

.modal-header .close {
  position: absolute;
  top: 0px;
  right: 10px;
  width: 30px;
  height: 30px;
  background: white;
  opacity: 1;
  line-height: 28px;
  border-radius: 50px;
  padding: 0px;
  text-align: center;
  box-shadow: 0 3px 9px rgb(0 0 0 / 50%);
}

.modal.show .modal-dialog.modal-full {
    top: 3%;
    height: 95%;
    padding: 0 5px;
    margin: 0 auto;
}
.modal-full .modal-content{
  height: 100%;
}
.modal-full .modal-footer {
  padding: 0 11px 11px 11px;
}

.modal .icon-box {
    height: 0;
    padding: 0;
    margin: 0;
    z-index: 1000;
    position: absolute;
    right: 2px;
    top: 0px;
}

.modal-header {
    padding-bottom: 0;
    padding-top: 0;
}







.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded="true"] {
  background: #ffa06a !important;
  color: white;
}
button.plyr__control.plyr__control--overlaid {
  top: 16%;
  right: 2%;
  left: auto;
}

.video-modal .modal-header {
  height: 0px !important;
}
.video-modal .modal-footer {
  padding: 0.25rem !important;
}

/* previewfizz modal end */

/* themedetail screen style starts */

.mob_buttonIcon {
  color: #095c9c;
  text-decoration: underline;
}

.greeting-price {
  color: #2c8636;
}

.greeting-price .cents {
  font-size: 75%;
  text-decoration: underline;
  padding: 2px;
}

/* themedetail screen style ends */

i.icon-mask {
  display: inline-block;
  width: 28px;
  height: 21px;
  background-color: #095c9c;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-size: contain;
  -webkit-mask-size: contain;
  margin-top: 5px;
  margin-bottom: -5px;
}



label.dropdown-item, a.dropdown-item, .dropdown-item a, .dropdown-item label {
    color: #603695;
    font-family: BeamNewHMK-114, sans-serif;
    font-weight: 400 !important;
}
a.dropdown-item:active {
  color: white !important;
  text-decoration: none !important;
}

/*  landing mailer  */
.mailer-segment {
  background: rgb(152, 219, 206);
  border-radius: 0px;
  padding: 10px;
  width: 100%;
  margin-top: 15px;
}

.btn-mailer {
  border-radius: 7px;
  border: 2px solid rgb(96, 64, 153);
  color: #604099;
  width: 159px;
  margin: 15px;
}

.form-control:focus {
  color: #48464f;
  background-color: white !important;
  border-color: #614199 !important;
}

.navbar-dark .navbar-nav .active>.nav-link, .navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .show>.nav-link {
  color: #614095 !important;
}

/* add video secondary btn start*/
.btn-group .btn-secondary{
  color: #614095;
  background-color: transparent;
  border-color: #614095;
}
.btn-group .btn-secondary:focus {
  color: #614095;
  background-color: transparent;
  border-color: #614095;
}
.btn-group .btn-secondary:hover {
  color: #614095;
  background-color: transparent;
  border-color: #614095;
}
.btn-group .btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle {
  color: #614095;
  background-color: transparent;
  border-color: #614095;
}

.inviteContinueButton{
  display: inline-block;
}

@media ( max-width: 991px ){
  .inviteContinueButton{
    display: block;
  }
}

.banner-bar {
  background: url(../img/hmk-banner-image.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  max-width: 1400px;
  height: 185px;
  margin: auto;
}

.banner-theme {
  background: url(../img/hmk-banner-theme-fake.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 50px;
  margin: auto;
}

.banner-container{
  background-color:#231f20;
  background-size: cover;
  height: 185px;

}

@media (max-width: 900px) {
  .banner-container{
    height: 100px;
  }
  .banner-bar {
    height: 100px;
  }
}


/* @media ( min-width: 1200px ){
  .banner-bar {
    background-size: 75%;
  }
} */

/* add video secondary btn end*/


/* 


.b1{
    width: 100px;  
    background:
      linear-gradient(to right, #604199 0.5px, transparent 0.5px) 0 0,
      linear-gradient(to right, #604199 0.5px, transparent 0.5px) 0 100%,
      linear-gradient(to left, #604199 0.5px, transparent 0.5px) 100% 0,
      linear-gradient(to left, #604199 0.5px, transparent 0.5px) 100% 100%,
      linear-gradient(to bottom, #604199 0.5px, transparent 0.5px) 0 0,
      linear-gradient(to bottom, #604199 0.5px, transparent 0.5px) 100% 0,
      linear-gradient(to top, #604199 0.5px, transparent 0.5px) 0 100%,
      linear-gradient(to top, #604199 0.5px, transparent 0.5px) 100% 100%;  
    background-repeat: no-repeat;
    background-size: 20px 20px;
}


@import url('https://fonts.googleapis.com/css?family=Roboto:300,400');
 body {
   background-color: white;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   height: 100vh;
}
 .content-wrapper {
   max-width: 80%;
   text-align: center;
}
 h1 {
   font-family: 'Roboto', sans-serif;
   font-weight: 300;
   color: #465159;
   margin-bottom: 4rem;
   font-size: 3rem;
}
 .corner-button:before, .corner-button span:before, .corner-button:after, .corner-button span:after {
  display: block;
  content: "";
  width: 8px;
  height: 8px;
  position: absolute;
  border-radius: 2px;
}
 .corner-button {
   display: inline-block;
   font-family: 'Roboto', sans-serif;
   font-size: 20px;
   position: relative;
   min-width: 150px;
   min-height: 30px;
   margin: 20px;
   background: none;
   border: none;
   text-decoration: none;
   cursor: pointer;
   color: #a4ca6d;
   padding: 12px 20px;
   box-sizing: content-box;
   border: 1px solid red;
   text-align: center;
   line-height: 30px;
   transition: 0.5s color;
   border-radius: 3px;
}
 .corner-button:before {
   top: -2px;
   left: -2px;
   border-top: 2px solid red;
   border-left: 2px solid red;
   transition: 0.5s all;
}
 .corner-button:after {
   top: -2px;
   right: -2px;
   border-top: 2px solid red;
   border-right: 2px solid red;
   transition: 0.5s all;
}
 .corner-button span {
   display: block;
}
 .corner-button span:before {
   bottom: -2px;
   left: -2px;
   border-bottom: 2px solid red;
   border-left: 2px solid red;
   transition: 0.5s all;
}
 .corner-button span:after {
   bottom: -2px;
   right: -2px;
   border-bottom: 2px solid red;
   border-right: 2px solid red;
   transition: 0.5s all;
}
 .corner-button:hover {
   color: #465159;
}
 .corner-button:hover:before, .corner-button:hover:after {
   border-color: #a4ca6d;
   width: 100%;
   height: 100%;
}
 .corner-button:hover span:before, .corner-button:hover span:after {
   border-color: #a4ca6d;
   width: 100%;
   height: 100%;
}
 button:hover span::before, button:hover span::after {
   width: 50px;
   height: 50px;
}
 
 */


.preview-processing-wrap {
  width: 100%;
  text-align: center;
  display: block;
  position: absolute;
  z-index: 600;
  top: 10px;
}
.preview-processing {    
  color:black;
  padding: 20px;
  background: rgba(255,255,255,0.9);
  width: 90%;
  z-index: 400;
  border-radius: 13px;
  margin: auto;
}
.preview-processing-text{
  font-size: 16px;
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  /* lg ~ Desktop */
  .preview-processing-text {
      font-size: 24px;
  }
  .preview-processing {
      width: 70%;
  }
  .preview-processing-wrap {
      top: 33%;
  }
}

@media (max-width: 425px) {
  /* A mobile size that seems right */
  .preview-processing {
    padding: 5px;
    width:95%;
  }
  .preview-processing-wrap {
    top: 5px;
  }
  .preview-processing-text {
    margin: 0;
  }
}





.alert-danger {
  background-color: #C54C4C!important;
}


@media (max-width: 768px) {
  .ta-row {
    width: 140vw;
  }
}

@media (min-width: 768px) {
  /* MD, LG, XL */
  .md-right-border {
    border-right: 2px solid #e0e0e0;
  }
}

.shared-fizz-title {
  font-weight: 500;
  padding-bottom: 8px;
  text-align: center;
}
.shared-fizz-title-name {
  font-family: 'IBM Plex Serif', sans-serif;
  padding: 12px 0;
  font-size: 30px;
  font-weight: 300;
  color: #48464f;
  margin: 14px 0 0 0;
}

.custom-headline {
  z-index: 1000;
  font-size: 58px; 
}

.custom-landing-video {
  z-index: 100;
  margin-top: -70px;
}

.custom-assortment-banner {
  background-color:#FFA976;
  margin-bottom: 45px;
}


@media ( max-width: 768px ){
  .custom-limit-text{
    margin-bottom: 25px;
  }

  .custom-landing-video {
    margin-top: -30px;
  }

  .custom-headline {
    font-size: 45px;
  }
}


@media ( max-width: 576px ){
  .custom-headline {
    font-size: 40px;
  }
}


@media (max-width: 415px) {
  .custom-headline {
    font-size: 35px;
  }
}

.custom-assortment-banner {
  background-color:#FFA976;
  margin-bottom: 45px;
}

@media ( max-width: 768px ){
  .custom-limit-text{
    margin-bottom: 25px;
  }

  .custom-landing-video {
    margin-top: -30px;
  }

  .custom-headline {
    font-size: 45px;
  }
}


@media ( max-width: 576px ){
  .custom-headline {
    font-size: 40px;
  }
}


@media (max-width: 415px) {
  .custom-headline {
    font-size: 35px;
  }
}


.video-views {
   margin-left: auto;
}

.list-date {

  font-weight: 200;
  font-size: 12px;
  /* marginBottom:"0" */
}

.disabled-link .list-name,
.disabled-link .list-date {
  opacity: 0.5;
}
.disabled-link .list-arrow {
  visibility: hidden;
}

.disabled-link-text {
  font-style: italic;
  color: #F2A473;
  font-weight: bold;
  margin-right: 30px;
}
