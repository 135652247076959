
/* record fizz design start */

.video-view {
  position: relative;
  /* width: 300px; */
  /* height: 100px; */
}

.video-view .video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #ccc;
}

.video-view .video-content {
  position: absolute;
  /* bottom: 0px; */
  right: 250px;
  bottom: 20px;
  padding: 20px;
}

.video_view_btn {
  background-color: transparent;
  border: none;
}
.video_view_btnimg {
  width: 50px;
}
.video-view .video-content {
  right: 0px;
  bottom: 20px;
  padding: 0px;
}

.video-view .box-480{
  background-color: black;
  position: relative;
}


@media (min-width: 768px)   {

  /* MD and larger */

  .video_view_btnimg {
    width: 100px;
  }

  .video-view .video-content {
    right: 20px;
    bottom: 20px;
    padding: 20px;
  }

}




.record-clock {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -60px;
    width: 120px;
    padding: 3px 0;
    background-color: rgba(255,255,255,0.5);
    text-align: center;
}

