.mob_nav_head {
  margin-top: 10px;
}
.heading_video {
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  min-height: 60px;
  margin-top: -1px; /*lets the top and botton overlap to make 1px instead of two*/
}
.heading_video p {
  margin-bottom: 0px;
  font-size: 15px;
}

.iconMoveVideo{
  padding: 30px;
  padding-left: 20px;
  margin-left: -20px;
}

.heading_direction{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.img_hero{
width:100%;
height:100%;
}
/* .heading_video .video-thumb {
  /* margin: 5px; 
} */
.invite_Share {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.preview_link {
  color: #007bff;
  font-size: 14px;
  text-decoration: 1px solid #007bff underline;
  padding: 10px;
  font-weight: 500;
}
.navbar-dark .navbar-toggler-icon {
  background-image: url(../images/HMK-Icons/Icons_Menu_Regular.svg);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text .link {
  color: #48464f;
}
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text .link:hover {
  color: #000000;
  text-decoration: underline;
}

/* hide close when burger shown */
.navbar-toggler.collapsed .close-icon {
  display: none;
}

.navbar-toggler:not(.collapsed) .navbar-toggler-icon {
  display: inline;
}
.navbar-toggler:not(.collapsed) .navbar-toggler-icon {
  display: block;
  background-image: url(../images/HMK-Icons/Icons_Menu_Close_Bold.svg) !important;
}

.video-fit {
  width: 100%;
}
.bg-margin {
  margin-bottom: 60px !important;
}
.form-container {
  padding: 20px 50px;
  /* padding: 50px; */
}

@media screen and (max-width: 425px) {
  .select_card {
    margin-bottom: 0px;
  }
  /* This is wrong should be 14px everywhere
  .btn {
    font-size: 12px;
  }
  */
  .intro_txt h1 {
    font-size: 25px;
  }
  .bg {
    bottom: 0px;
  }
  .invite_btn {
    margin: 10px 0px;
    position: relative;
  }
  .cardvideo .card {
    width: 260px;
  }
}

.login_page_block {
  overflow: hidden;
}

.bottom_btn {
  position: absolute;
  bottom: 90px;
}

@media (min-width: 1441px) and (max-width: 2560px) {
  .login-form {
    width: 310px !important;
  }
  .mob_pagetitle {
    display: none;
  }
  .navbar .dropdown-toggle {
    display: none;
  }
  .mob_sharebtn {
    display: none;
  }
  /* This is wrong should be 14px everywhere
  .btn {
    font-size: 12px;
  }
  */
  .FAQ_list {
    display: none;
  }
  .mob_account1 {
    visibility: hidden;
  }
  .navbar-nav li {
    display: block;
  }
  .personalize_container {
    height: 400px;
  }
  @-moz-document url-prefix() {
    footer {
      margin-top: 200px;
    }
  }
}


@media (min-width: 1441px) {
  .arrow {
    display: none;
  }
}

@media (min-width: 991px) and (max-width: 1024px) {
  .mob_account1 {
    visibility: hidden;
  }
  .arrow {
    display: none;
  }
  .personalize_container {
    height: 400px !important;
  }
  @-moz-document url-prefix() {
    footer {
      margin-top: 200px;
    }
  }
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .login-form {
    width: 310px !important;
  }
  .mob_pagetitle {
    display: none;
  }
  .navbar .dropdown-toggle {
    display: none;
  }
  .mob_sharebtn {
    display: none;
  }
  /* This is wrong should be 14px everywhere
  .btn {
    font-size: 12px;
  }
  */
  .FAQ_list,
  .arrow {
    display: none;
  }
  .mob_account1 {
    visibility: hidden;
  }
  .navbar-nav li {
    display: block;
  }
  /* DONT hide with css, use bootstrap classes
  .footer_inner li:nth-of-type(1),
  li:nth-of-type(2),
  li:nth-of-type(3) {
    display: block;
  }
  */
  .video_clips {
    margin: 10px 100px;
  }
  
  .personalize_container {
    height: 300px !important;
  }

  @-moz-document url-prefix() {
    footer {
      margin-top: 200px;
    }
  }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/
@media (min-width: 481px) and (max-width: 767px) {
  .invite_Share {
    display: flex;
    flex-direction: column !important;
  }
  .bg-dark {
    display: flex;
    flex-direction: row-reverse;
  }
  .mob_buttonIcon {
    font-size: 0.9rem;
  }
  .buttonIcon {
    margin: 3px;
    font-size: 0.9rem;
  }
  .theme_name {
    font-size: 0.9rem;
  }
  .vflogo img {
    display: none;
  }
  .mob_pagetitle {
    display: block;
    font-size: 16px;
    font-weight: 600;
  }
  .navbar .dropdown-toggle::after {
    display: none;
  }
  .mob_nav_head {
    display: none;
  }
  .mob_sharebtn {
    display: none;
  }
  .navbar-nav li {
    display: block;
  }
  .mob_account1 {
    padding: 0px 20px;
  }
  .mob_account1 img {
    padding-right: 15px;
  }
  .form-container {
    padding: 20px;
  }
  @-moz-document url-prefix() {
    footer {
      margin-top: 200px;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .bg-dark {
    padding: 0px;
    display: flex;
    flex-direction: row-reverse;
  }

  .mob_buttonIcon {
    font-size: 0.9rem;
  }
  .buttonIcon {
    margin: 3px;
    font-size: 0.9rem;
  }
  .theme_name {
    font-size: 0.9rem;
  }
  .vflogo img {
    display: none;
  }
  .mob_pagetitle {
    display: block;
    font-size: 16px;
    font-weight: 600;
  }
  .navbar .dropdown-toggle::after {
    display: none;
  }
  .mob_nav_head {
    display: none;
  }
  .mob_sharebtn {
    display: none;
  }
  .navbar-nav li {
    display: block;
  }
  .mob_account1 {
    padding: 20px;
  }
  .mob_account1 img {
    padding-right: 15px;
  }
  .form-container {
    padding: 20px;
  }
  .personalize_container {
    /* height: 0px !important; */
  }
  @-moz-document url-prefix() {
    footer {
      margin-top: 200px;
    }
  }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .form-align {
    justify-content: center;
  }

  /* .navbar {
    display: none;
  } */

  /* .navbar {
    z-index: 0;
  } */

  .navbar .dropdown-menu {
    left: -100px !important;
    background-color: #614095;
  }

  .navbar .dropdown-toggle::after {
    display: none;
  }
  .navbar-dark .navbar-nav .nav-link {
    border-top: 1px solid #b3b0b9;
  } 
  .mob_account1 {
    /* display: none; */
    padding: 0px 20px;
    border-top: 1px solid #b3b0b9;
    /* border-top: 0.5px solid #614095; */
  }
  .mob_account1 img {
    padding-right: 15px;
  }

  .bg-dark {
    display: flex;
    flex-direction: row-reverse;
  }

  .vflogo img {
    display: none;
  }
  .mob_pagetitle {
    display: block;
    font-size: 16px;
    font-weight: 600;
  }
  .mob_nav_head {
    background-color: #603695 !important;
    color: white;
    margin-top: 0px;
    display: none;
  }
  .iconBox {
    width: 70px;
    height: 70px;
  }
  .iconBoxBack {
    width: 70px;
    height: 70px;
    background-color: transparent;
    margin: 0;
  }
  .mob_occasionname {
    display: flex;
  }
  .iconBoxBack h6 {
    padding-top: 20px;
    min-width: max-content;
    margin-left: 15px;
  }
  .mob_buttonIcon {
    font-size: 16px;
  }
  .buttonIcon {
    padding: 4px !important;
    margin: 3px;
    font-size: 0.6rem;
  }
  .theme_name {
    font-size: 0.6rem;
  }
  .cardbody_mobfizz {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0rem;
  }
  .cardbody_mobfizz h5 {
    font-size: 0.8rem;
  }
  .cardbody_mobfizz .customButton {
    margin-left: auto;
    color: #614095;
    background: none;
    padding: 0;
  }
  .cardbody_mobfizz .delete_btn {
    display: none;
  }
  .no_gutters_mob {
    flex-direction: column-reverse;
  }
  .invite_Share {
    display: flex;
    flex-direction: column !important;
  }
  .footer_inner {
    padding: 0px;
    font-size: 14px;
  }
  .bg-dark {
    background-color: white !important;
  }
  .navbar-nav li {
    display: block;
    /* border-top: 0.5px solid #614095; */
  }
  .navbar-brand img {
    /* width: 150px; */
  }
  .mob_button_block {
    width: 100%;
    text-align: center !important;
    margin-bottom: 5px;
  }
  .form-container {
    padding: 20px !important;
  }
  
/* .img_hero{
    width:210px;
    height:200px;
  } */
  /* .greeting-content {
    display: none;
  } */

  /* .react-multi-carousel-list {
    display: block !important;
  }
  .step_loader .react-multi-carousel-dot-list {
    margin-top:30px !important;
  }
  .step_snap {
    min-width: 270px;
    margin:10px 10 !important;
  }
  .step_container {
    padding: 0px 10px 0px 0px;
  }

element.style {
  flex: 1 1 auto;
  position: relative;
}
.react-multi-carousel-item {
  width:270px !important;
} */
@-moz-document url-prefix() {
  footer {
    margin-top: 200px;
  }
}

}
@media (min-width: 992px) {
  .next-btn {
    padding-left: 75px;
    padding-right: 75px;
  }
}


/*************** MOBILE *************/

.personalize_container {
  display: flex;
  flex-direction: column;
  /* height: 300px; */
}
div.video_img {
  order: 3;
}
div.insert_name {
  order: 1;
}
.personalize_container > div {
  width: 100%;
  flex: 1;
  display: inline;
  align-items: center;
  justify-content: center;
}
/***************************/
@media (min-width: 800px) {
  .personalize_container {
    flex-wrap: wrap;
    margin-top: 20px;
    height: 300px;
  }
  div.video_img {
    flex-basis: 100%;
    /* width: 45%; */
    width: 450px;
    order: 3;
    /* margin: 0px auto; */
  }
  div.insert_name {
    flex-basis: 50%;
    width: 100%;
    order: 1;
  }

  .step_container {
    padding: 0px 10px;
    margin: 20px auto;
  }
}



@media (max-width: 991px) {
  /* mobile only */
  .p-mobile-0 {
    padding: 0;
  }
}

